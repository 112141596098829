import { useLayoutEffect } from 'react';
import { throttle } from '../utils/throttle';
import Layout from './Layout';
import SchullebenGallery from './gallery/SchullebenGallery';

const Schulleben = () => {
  useLayoutEffect(() => {
    const onScroll = throttle((evt) => {
      //console.dir(evt.target.scrollingElement.scrollTop);
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector('header').classList.add('shrink-header');
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector('header').classList.remove('shrink-header');
        }
      }
    }, 15);
    window.addEventListener('scroll', onScroll);
    return () => {
      document.querySelector('header').classList.remove('shrink-header');
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Layout layoutClass='schulleben' activeNavItem='Schulleben'>
      {/* <ContentContainer /> */}
      <SchullebenGallery />
    </Layout>
  );
};

export default Schulleben;
