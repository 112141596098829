import { useState, useEffect } from "react";
//import { CookieBanner } from "@palmabit/react-cookie-law";
import { cardData } from "../data/cardData";
import Layout from "./Layout";
import Loader from "./Loader";
import CardContainer from "./cards/CardContainer";

const HomePage = () => {
  const heading = `Katholische Schule St. Marien Eulenstraße`; //\ Schultradition von 19 … - 2021
  const subHeading = "\nSchultradition von 1889 - 2021";
  const [loadingDone, setFinishedLoading] = useState(false);
  const [pageContent, setPageContent] = useState();

  useEffect(() => {
    const getPageData = async () => {
      const req = await fetch(
        process.env.REACT_APP_WP_API_URL + "pages?slug=einleitung"
      );
      const reqJson = await req.json();
      setPageContent(reqJson[0].content.rendered);
    };
    getPageData();
    const loadImg = async () => {
      const img = new Image();
      img.onload = () => {
        setFinishedLoading(true);
      };
      img.src = "img/unsere-schule-eulenstrasse.jpg";
    };
    loadImg();
  }, []);

  return (
    <Layout layoutClass="home">
      {loadingDone ? (
        <>
          <h1 className="title-alt">
            {heading}
            <span>{subHeading}</span>
          </h1>
          <CardContainer data={cardData} />
          <div
            className="text-content"
            dangerouslySetInnerHTML={{
              __html: pageContent && pageContent.length ? pageContent : null,
            }}
          ></div>
        </>
      ) : (
        <div
          style={{ minHeight: "50vh" }}
          className={
            loadingDone ? "loader-holder" : "loader-holder loader-visible"
          }
        >
          <Loader />
        </div>
      )}
    </Layout>
  );
};

export default HomePage;
