const Hamburger = ({ onClick, isActive }) => {
  return (
    <div
      onClick={onClick}
      className={
        isActive
          ? "hamburger hamburger--slider is-active"
          : "hamburger hamburger--slider"
      }
      type="button"
    >
      <div className="hamburger-box">
        <div className="hamburger-inner"></div>
      </div>
    </div>
  );
};

export default Hamburger;
