const cardData = [
  [
    {
      bgClass: "primary-bg",
      animClass: "reveal-1",
      bgImg: "schulleben.jpg",
      posClass: "left-bottom-pos",
      bgColorDelay: "0.4s",
      target: "/geschichte",
      heading: "Geschichte",
      icon: "book-closed",
    },
    {
      bgClass: "secondary-bg",
      animClass: "reveal-2",
      bgImg: "schulleben.jpg",
      posClass: "right-bottom-pos",
      bgColorDelay: "0.7s",
      target: "/erinnerungen",
      heading: "Erinnerungen",
      icon: "leaf",
    },
  ],
  [
    {
      bgClass: "third-bg",
      animClass: "reveal-3",
      bgImg: "schulleben.jpg",
      posClass: "left-bottom-pos",
      bgColorDelay: "0.9s",
      target: "/schulleben",
      heading: "Schulleben",
      icon: "backpack-right",
    },
    {
      bgClass: "fourth-bg",
      animClass: "reveal-4",
      bgImg: "schulleben.jpg",
      posClass: "right-bottom-pos",
      bgColorDelay: "1.1s",
      target: "/gaestebuch",
      heading: "Gästebuch",
      icon: "book-open",
    },
  ],
];

export { cardData };
