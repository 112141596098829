import React from "react";

const Loader = ({ bgClass, posClass }) => {
  return (
    <div
      className={posClass ? "sk-fading-circle " + posClass : "sk-fading-circle"}
    >
      {Array.from(Array(12), (item, idx) => {
        return (
          <div
            key={idx}
            className={
              bgClass
                ? `sk-circle${++idx} sk-circle ${bgClass}`
                : `sk-circle${++idx} sk-circle`
            }
          ></div>
        );
      })}
    </div>
  );
};

export default Loader;
