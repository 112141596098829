import { useState, useEffect, useLayoutEffect } from "react";
import { throttle } from "../utils/throttle";
import { getCookie } from "../utils/getCookie";
import { getPostProp } from "../utils/getPostProp";
import Layout from "./Layout";
import Postings from "./postings/Postings";
import Modal from "./Modal";

const Archiv = ({ subSite, parentItem }) => {
  const [doneLoading, setDoneLoading] = useState(false);
  const [postings, setPostings] = useState([]);
  const [showModal, setShowModal] = useState({
    active: false,
    src: {},
    isVid: false,
    itText: false,
    index: 0,
  });
  const onModalDismiss = () => {
    setShowModal({
      active: false,
      src: {},
      isVid: false,
      isText: false,
      index: 0,
    });
  };

  const findPostById = (id) =>
    postings.filter((post) => post.id === parseInt(id))[0];

  const onPostClick = ({ currentTarget }) => {
    const index = currentTarget.dataset.id;
    const content = getPostProp(findPostById(index), ["content"]);
    setShowModal((prevState) => ({
      ...prevState,
      active: true,
      src: { content: content },
      isVid: false,
      isText: true,
      index,
    }));
  };

  const prevNextImg = () => {};

  useLayoutEffect(() => {
    const onScroll = throttle((evt) => {
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector("header").classList.add("shrink-header");
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector("header").classList.remove("shrink-header");
        }
      }
    }, 15);
    window.addEventListener("scroll", onScroll);
    return () => {
      document.querySelector("header").classList.remove("shrink-header");
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    const getPageData = async () => {
      const req = await fetch(
        process.env.REACT_APP_WP_API_URL +
          "posts?filter[categories]=Archiv%2BMedien&per_page=100&_embed"
      );
      const reqJson = await req.json();
      const postingsTmp = reqJson.map((post) => {
        const excerpt = post.excerpt.rendered;
        let img = null;
        return {
          id: post.id,
          marketing: post.marketing ? post.marketing : "",
          category: "text",
          heading: post.title.rendered,
          date: new Date(post.modified).toLocaleDateString(),
          excerpt: excerpt.length ? excerpt : "",
          content: post.content.rendered,
          authorName: post.autor
            ? post.autor
            : "Katholische Schule St. Marien Eulenstraße",
          authorAvatar: "/img/eule.webp",
          img: img ? img : "",
          embedded: post._embedded,
          youtube: post.youtube,
        };
      });

      if (getCookie("rcl_preferences_consent")) {
        setPostings(postingsTmp);
        setDoneLoading(true);
      } else {
        const noVids = postingsTmp.filter((post) => post.marketing !== "video");
        setPostings(noVids);
        setDoneLoading(true);
      }
    };
    getPageData();
  }, []);

  return (
    <Layout layoutClass="erinnerungen" activeNavItem={parentItem[subSite]}>
      <Modal
        showModal={showModal}
        onModalDismiss={onModalDismiss}
        prevNextImg={prevNextImg}
        showPrevNext={false}
      />
      <Postings
        showDate={true}
        data={postings}
        onPostClick={onPostClick}
        doneLoading={doneLoading}
        pageContent={{
          header: "Archiv",
          content:
            "Hier findet ihr ältere Beiträge aus unserem Archiv. Viel Spaß beim stöbern.",
        }}
      />
    </Layout>
  );
};

export default Archiv;
