export const getPostProp = (post, prop) => {
  let result = null;
  prop.forEach((prop, id) => {
    if (id) {
      result = result[prop];
    } else {
      result = post[prop];
    }
  });
  return result;
};
