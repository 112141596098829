import { Link } from "@reach/router";
import { navItems } from "../data/navData";

const Nav = ({ isActive, activeItem }) => {
  return (
    <nav className={isActive ? "is-active" : ""}>
      {navItems.map((item) => {
        return (
          <div key={item.target} className="nav-link-container">
            <Link
              className={activeItem === item.text ? "active" : ""}
              to={item.target}
            >
              {item.text}
            </Link>
            {item.submenu.length ? (
              <div className="sub-menu-container">
                {item.submenu.map((submenuItem) => {
                  return (
                    <Link key={submenuItem.target} to={submenuItem.target}>
                      {submenuItem.text}
                    </Link>
                  );
                })}
              </div>
            ) : null}
          </div>
        );
      })}
    </nav>
  );
};

export default Nav;
