import { useEffect, useRef } from "react";
import Loader from "../Loader";
import Post from "./Post";

const Postings = ({
  data,
  pageContent,
  doneLoading,
  onPostClick,
  showDate,
}) => {
  const postsContainer = useRef();
  const animationEndCount = useRef(1);

  const setAnimDelay = (random) => {
    Array.from(postsContainer.current.children).forEach((item) => {
      item.style.transitionDelay = random
        ? Math.random().toFixed(2) + "s"
        : "0s";
      random && item.classList.add("show-post");
    });
    animationEndCount.current = 1;
  };

  useEffect(() => {
    if (doneLoading) {
      setTimeout(() => {
        setAnimDelay(true);
      }, 0);
    }
    return () => {};
  }, [doneLoading, data]);

  const onTransitionEnd = (event) => {
    animationEndCount.current = animationEndCount.current + 1;
    if (animationEndCount.current === data.length) {
      setAnimDelay(false);
    }
  };

  return (
    <div className="container">
      <div className="main-heading">
        <h1>{pageContent && pageContent.header}</h1>
      </div>
      <div
        className="mobile-text"
        dangerouslySetInnerHTML={{
          __html: pageContent && pageContent.content,
        }}
      />
      <div
        className={
          doneLoading ? "loader-holder" : "loader-holder loader-visible"
        }
      >
        <Loader />
      </div>
      <div ref={postsContainer} className="post-grid-container">
        {data.map((post) => (
          <Post
            onPostClick={onPostClick}
            showDate={showDate}
            onTransitionEnd={onTransitionEnd}
            key={post.id}
            {...post}
          />
        ))}
      </div>
    </div>
  );
};

export default Postings;
