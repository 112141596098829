import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { fetcher } from "../utils/fetcher";
import { imgLoader } from "../utils/imgLoader";
import { getPostProp } from "../utils/getPostProp";
//import { timelineData } from "../data/timelineData";
import { throttle } from "../utils/throttle";
import Layout from "./Layout";
import Loader from "./Loader";

const Timeline = React.forwardRef((props, ref) => {
  const { pageContent } = props;
  return (
    <div className="container">
      <div className="main-heading">
        <h1>{pageContent ? pageContent.header : null}</h1>
      </div>
      <div
        className="mobile-text"
        dangerouslySetInnerHTML={{
          __html: pageContent && pageContent.content,
        }}
      />

      <div
        className={
          props.doneLoading ? "loader-holder" : "loader-holder loader-visible"
        }
      >
        <Loader />
      </div>

      <ul
        ref={ref}
        className={props.doneLoading ? "timeline done-loading" : "timeline"}
      >
        {props.data.map((item, idx) => {
          return (
            <li key={idx}>
              <div className="timeline-badge">{item.date}</div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h2>{item.heading}</h2>
                  {/* <p>{item.date}</p> */}
                </div>
                <div className="timeline-body">
                  {item.img ? <img src={item.img} alt={item.date} /> : null}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
});

const Geschichte = () => {
  const [doneLoading, setDoneLoading] = useState(false);
  const [pageContent, setPageContent] = useState();
  const [timelineData, setTimelineData] = useState([]);
  const padding = 50;
  const [offSet, setOffset] = useState(window.innerHeight - padding);
  const timelineItems = useRef();

  const timelineList = useCallback(
    (node) => {
      if (node !== null && doneLoading) {
        timelineItems.current = Array.from(node.children).map((child) => {
          const panel = child.querySelector(".timeline-panel");
          return {
            el: panel,
            top: panel.getBoundingClientRect().top,
          };
        });
      }
    },
    [doneLoading]
  );

  useEffect(() => {
    fetcher(process.env.REACT_APP_WP_API_URL + "pages?slug=geschichte").then(
      (data) => {
        //console.log(data);
        setPageContent({
          header: data[0].title.rendered,
          content: data[0].content.rendered,
        });
      }
    );
    fetcher(
      process.env.REACT_APP_WP_API_URL +
        "geschichte?per_page=100&_embed&filter[orderby]=date&order=asc"
    ).then((data) => {
      const tmpItems = data.map((item) => {
        let img = null;
        if (item._embedded.hasOwnProperty("wp:featuredmedia")) {
          img = getPostProp(item, [
            "_embedded",
            "wp:featuredmedia",
            "0",
            "source_url",
          ]);
        }
        return {
          date: new Date(item.date).getFullYear(),
          img: img,
          heading: item.title.rendered,
          text: item.content.rendered,
        };
      });

      setTimelineData(tmpItems);
    });
  }, []);

  useEffect(() => {
    if (timelineData.length) {
      imgLoader(timelineData).then((values) => {
        setDoneLoading(true);
      });
    }
  }, [timelineData]);

  const showPanel = (scrollPos) => {
    if (timelineItems.current) {
      timelineItems.current.forEach((child, idx) => {
        if (scrollPos > child.top) {
          if (idx % 2) {
            child.el.classList.add("right-test");
          } else {
            child.el.classList.add("left-test");
          }
        }
      });
    }
  };

  const onResize = (evt) => {
    setOffset(window.innerHeight - padding);
  };

  useLayoutEffect(() => {
    const onScroll = throttle((evt) => {
      //console.dir(evt.target.scrollingElement.scrollTop);
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector("header").classList.add("shrink-header");
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector("header").classList.remove("shrink-header");
        }
      }
      showPanel(window.scrollY + offSet);
    }, 15);

    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);
    return () => {
      document.querySelector("header").classList.remove("shrink-header");
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
    };
  }, [timelineList, offSet]);

  useEffect(() => {
    setTimeout(() => {
      showPanel(window.innerHeight);
    });
  }, [doneLoading]);

  return (
    <Layout layoutClass="geschichte" activeNavItem="Geschichte">
      <Timeline
        ref={timelineList}
        data={timelineData}
        pageContent={pageContent}
        doneLoading={doneLoading}
      />
    </Layout>
  );
};

export default Geschichte;
