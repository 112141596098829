import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { imgLoader } from "../../utils/imgLoader";
import { fetcher } from "../../utils/fetcher";
import Loader from "../Loader";
import Modal from "../Modal";

const SchullebenGallery = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [galleries, setGalleries] = useState([]);
  const [pageContent, setPageContent] = useState();
  const [showModal, setShowModal] = useState({
    active: false,
    src: {},
    isVid: false,
    isText: false,
    index: 0,
  });
  const galleryContainer = useRef();
  const animationEndCount = useRef(1);

  const setAnimDelay = (random) => {
    if (galleryContainer.current) {
      Array.from(galleryContainer.current.children).forEach((item) => {
        item.style.transitionDelay = random
          ? Math.random().toFixed(2) + "s"
          : "0s";
        random && item.classList.add("show-figure");
      });
      animationEndCount.current = 1;
    }
  };

  const onTransitionEnd = (event) => {
    animationEndCount.current = animationEndCount.current + 1;
    if (
      animationEndCount.current === galleryContainer.current.children.length
    ) {
      setAnimDelay(false);
    }
  };

  const onModalDismiss = () => {
    setShowModal({
      active: false,
      src: {},
      isVid: false,
      isText: false,
      index: 0,
    });
  };

  const prevNextImg = ({ currentTarget }) => {
    const {
      dataset: { direction },
    } = currentTarget;

    const activeGallery = galleries.filter((gal) => gal.active)[0];
    const idxNum = parseInt(showModal.index);
    const len = activeGallery.content.length;

    const nextPrevIdx =
      direction === "left"
        ? idxNum === 0
          ? len - 1
          : idxNum - 1
        : idxNum === len - 1
        ? 0
        : idxNum + 1;

    const newSrc = galleries.filter((gal) => gal.active)[0].content[
      nextPrevIdx
    ];
    let currSrc = {};
    newSrc.srcset.forEach((item, idx, arr) => {
      if (parseInt(item.size.replace("w", "")) <= window.innerWidth) {
        currSrc = { src: item.src, size: item.size };
      }
    });

    setShowModal({
      active: true,
      src: {},
      isVid: false,
      isText: false,
      index: 0,
    });

    setTimeout(() => {
      setShowModal({
        active: true,
        src: newSrc.alt.length ? { src: newSrc.alt } : currSrc,
        isVid: newSrc.alt.length ? true : false,
        isText: false,
        index: nextPrevIdx,
      });
    }, 100);

    // console.log(nextPrevIdx);
    // console.log(galleries.filter((gal) => gal.active)[0].content[nextPrevIdx]);
  };

  const onImgClick = ({ target }) => {
    const {
      alt,
      dataset: { srcset, index },
    } = target;
    if (alt.length) {
      //console.log("show vid");
      setShowModal((prevState) => ({
        ...prevState,
        active: true,
        src: { src: alt },
        isVid: true,
        isText: false,
        index,
      }));
    } else {
      //console.log("show img");
      const srcsetJSON = JSON.parse(srcset);
      let currSrc = {};
      srcsetJSON.forEach((item, idx, arr) => {
        if (parseInt(item.size.replace("w", "")) <= window.innerWidth) {
          currSrc = { src: item.src, size: item.size };
        }
      });
      setShowModal((prevState) => ({
        ...prevState,
        active: true,
        src: currSrc,
        isVid: false,
        isText: false,
        index,
      }));
    }
  };

  const onFilterClick = (evt) => {
    setIsLoading(true);
    const modGals = galleries
      .map((gallery) => ({ ...gallery, active: false }))
      .map((gallery) => {
        if (gallery.title === evt.target.textContent) {
          return { ...gallery, active: true };
        }
        return gallery;
      });
    setGalleries(modGals);
  };

  useEffect(() => {
    //console.log("called use effect FETCHER");
    fetcher(process.env.REACT_APP_WP_API_URL + "pages?slug=schulleben").then(
      (data) => {
        setPageContent({
          header: data[0].title.rendered,
          content: data[0].content.rendered,
        });
      }
    );
    fetcher(process.env.REACT_APP_WP_API_URL + "schulleben").then((data) => {
      const gals = data.map((item, idx) => {
        const tmp = document.createElement("template");
        tmp.innerHTML = item.content.rendered;
        const content = Array.from(tmp.content.querySelectorAll("img")).map(
          (node, idx) => {
            return {
              id: idx,
              img: node.src,
              alt: node.alt,
              srcset: node.srcset.split(",").map((src) => ({
                src: src.trim().split(" ")[0],
                size: src.trim().split(" ")[1],
              })),
            };
          }
        );
        return {
          id: item.id,
          title: item.title.rendered,
          content: content,
          active: idx === 0 ? true : false,
        };
      });
      setGalleries(gals);
    });
  }, []);

  useLayoutEffect(() => {
    //console.log("called use effect IMG LOADER");
    if (galleries.length) {
      const activeGallery = galleries.filter((gallery) => gallery.active);
      imgLoader(activeGallery[0].content).then((values) => {
        setIsLoading(false);
        setTimeout(() => {
          setAnimDelay(true);
        }, 0);
      });
    }
  }, [galleries]);

  const renderActiveGallery = (gallery) => {
    if (galleries.length) {
      return gallery[0].content.map((img) => {
        return (
          <div
            key={img.img}
            onTransitionEnd={onTransitionEnd}
            className="gallery-item"
          >
            <img
              src={img.img}
              alt={img.alt}
              data-srcset={JSON.stringify(img.srcset)}
              data-index={img.id}
              onClick={onImgClick}
            />
          </div>
        );
      });
    }
    return null;
  };

  return (
    <>
      <Modal
        showModal={showModal}
        onModalDismiss={onModalDismiss}
        prevNextImg={prevNextImg}
        showPrevNext={true}
      />
      <div className="container">
        <div className="main-heading">
          <h1>{pageContent && pageContent.header}</h1>
        </div>
        <div
          className="mobile-text"
          dangerouslySetInnerHTML={{
            __html: pageContent && pageContent.content,
          }}
        />
        <div className="gallery-filter">
          <nav>
            {galleries.length
              ? galleries.map((gal) => (
                  <div
                    key={gal.title}
                    onClick={onFilterClick}
                    className={gal.active ? "active" : ""}
                  >
                    {gal.title}
                  </div>
                ))
              : null}
          </nav>
        </div>

        <div
          className={
            isLoading ? "loader-holder loader-visible" : "loader-holder"
          }
        >
          <Loader />
        </div>
        <div
          ref={galleryContainer}
          className={isLoading ? "gallery" : "gallery gallery-visible"}
        >
          {renderActiveGallery(galleries.filter((gallery) => gallery.active))}
        </div>
      </div>
    </>
  );
};

export default SchullebenGallery;
