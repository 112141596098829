import { useEffect, useState } from "react";
import { imgLoader } from "../utils/imgLoader";

import { BsX, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Loader from "./Loader";

const Modal = ({ showModal, onModalDismiss, prevNextImg, showPrevNext }) => {
  const { active, src, isVid, isText } = showModal;
  const [showLoader, setShowLoader] = useState(true);
  const [imgClass, setImgClass] = useState("");
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (active) {
      document.body.classList.add("is-modal");
    } else {
      document.body.classList.remove("is-modal");
    }
  }, [active]);

  useEffect(() => {
    if (isText) {
      setShowLoader(false);
    } else {
      if (isVid) {
        if (src.hasOwnProperty("src") && src.src.length) {
          setImgSrc(src.src);
          setShowLoader(false);
        }
      } else {
        if (src.hasOwnProperty("src") && src.src.length) {
          //console.log("loading img");
          imgLoader([{ img: src.src }]).then((img) => {
            setImgClass(() => {
              //console.log(img);
              //console.log("img width: ", img[0].width);
              //console.log("img height: ", img[0].height);
              return img[0].width > img[0].height ? "landscape" : "portrait";
            });
            setImgSrc(src.src);
            setShowLoader(false);
          });
        } else {
          setImgSrc("");
          setShowLoader(true);
        }
      }
    }
  }, [src, isVid, isText]);

  const getImgSize = (sizes) => {
    if (sizes.hasOwnProperty("large")) {
      return sizes["large"]["source_url"];
    }
    return sizes["full"]["source_url"];
  };

  const renderTextContent = (src) => {
    if (src.img) {
      // console.log(src);
      return (
        <div className="modal-content">
          <img
            className="phat-border white-border"
            src={getImgSize(src.img)}
            alt="Erinnerung"
          />
          <div
            dangerouslySetInnerHTML={{
              __html: src.content.length ? src.content : null,
            }}
            className="text-content-modal"
          />
        </div>
      );
    } else {
      return (
        <div className="modal-content">
          <div
            dangerouslySetInnerHTML={{
              __html: src.content.length ? src.content : null,
            }}
            className="text-content-modal"
          />
        </div>
      );
    }
  };

  return (
    <div className={active ? "gallery-modal show-modal" : "gallery-modal"}>
      <div className="modal-container">
        <div className="modal-dismiss" onClick={onModalDismiss}>
          <BsX color="black" size={48} />
        </div>
        <div className="modal-body">
          <div
            className={
              showLoader ? "loader-holder loader-visible" : "loader-holder"
            }
          >
            <Loader posClass="vertical-center" />
          </div>
          {isVid ? (
            <div className="vid-container-wrapper">
              <div className="vid-container">
                <iframe
                  width="560"
                  height="315"
                  src={"https://www.youtube-nocookie.com/embed/" + src.src}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          ) : isText ? (
            renderTextContent(src)
          ) : (
            <div className={`img-container ${imgClass}`}>
              <img
                src={imgSrc}
                alt=""
                className={imgSrc && imgSrc.length ? "show-img" : ""}
              />
            </div>
          )}
        </div>
        {showPrevNext ? (
          <div className="gallery-controls">
            <div className="left" onClick={prevNextImg} data-direction="left">
              <BsChevronLeft color="black" size={36} />
            </div>
            <div className="right" onClick={prevNextImg} data-direction="right">
              <BsChevronRight color="black" size={36} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Modal;
