import React from "react";
import { Router } from "@reach/router";
import { hashMapSubmenu, getParentItem, navItems } from "./data/navData";
import { guestbookData } from "./data/guestbookData";
import HomePage from "./components/HomePage";
import Schulleben from "./components/Schulleben";
import Geschichte from "./components/Geschichte";
import Erinnerungen from "./components/Erinnerungen";
import Subsite from "./components/SubSite";
import Archiv from "./components/Archiv";
import Gaestebuch from "./components/guestbook/Guestbook";
import Disclaimers from "./components/Disclaimers";
/*-------------------------------------------------------
404
*/
const NotFound = () => (
  <div>Entschuldige, aber hier gibt es nichts zu sehen.</div>
);
/*-------------------------------------------------------
App
*/
const ScrollToTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname]);
  return children;
};

function App() {
  return (
    <Router>
      <ScrollToTop path="/">
        <HomePage path="/" />
        <Geschichte path="/geschichte" />
        <Subsite
          parentItem={getParentItem(navItems)}
          hashMap={hashMapSubmenu()}
          path="/geschichte/:subSite"
        />
        <Erinnerungen path="/erinnerungen" />
        <Subsite
          parentItem={getParentItem(navItems)}
          hashMap={hashMapSubmenu()}
          path="/erinnerungen/:subSite"
        />
        <Schulleben path="/schulleben" />
        <Subsite
          parentItem={getParentItem(navItems)}
          hashMap={hashMapSubmenu()}
          path="/schulleben/kulturforum-21"
          subSite="kulturforum-21"
        />
        <Archiv
          parentItem={getParentItem(navItems)}
          hashMap={hashMapSubmenu()}
          path="/schulleben/archiv"
          subSite="archiv"
        />
        <Gaestebuch data={guestbookData} path="/gaestebuch" />
        <Disclaimers path="/:disclaimer" />
        <NotFound default />
      </ScrollToTop>
    </Router>
  );
}

export default App;
