import { useLayoutEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card";
// import Logo from "../Logo";

const renderCards = (cardRow) => {
  return (
    <div key={uuidv4()} className="card-row">
      {cardRow.map((card) => (
        <Card key={uuidv4()} {...card} />
      ))}
    </div>
  );
};

const CardContainer = ({ data }) => {
  const imgRef = useRef();

  useLayoutEffect(() => {
    if (imgRef.current) {
      setTimeout(() => {
        imgRef.current.classList.add("show-bg-img");
      }, 50);
      //
    }
  }, [data]);

  return (
    <nav className="container">
      <div ref={imgRef} className="bg-img-holder"></div>
      {/* <Logo finishedLoading={finishedLoading} isHome={true} /> */}
      {data.map(renderCards)}
    </nav>
  );
};

export default CardContainer;
