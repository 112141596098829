import { useEffect, useState } from "react";
import { Link } from "@reach/router";
import Nav from "./Nav";
import Logo from "./Logo";
import Hamburger from "./Hamburger";
import { CookieBanner } from "@palmabit/react-cookie-law";

const Footer = ({ isLoading }) => {
  return (
    <footer className={isLoading ? "" : "show-footer"}>
      <div>
        <span className="copyright">
          {`${document.location.host} ${new Date().getFullYear()}`}
        </span>
      </div>
      <div>
        <Link to="/impressum">Impressum</Link>
        <Link to="/datenschutz">Datenschutz</Link>
      </div>
    </footer>
  );
};

const Layout = ({ children, layoutClass, activeNavItem }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isToggleNavActive, setToggleNavActive] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const onBurgerClick = () => {
    setToggleNavActive((prevState) => !prevState);
  };

  return (
    <div className={layoutClass}>
      <CookieBanner
        policyLink="/datenschutz"
        privacyPolicyLinkText="Hier geht es zu usere Datenschutzrichtlinien"
        managePreferencesButtonText="Details Anzeigen"
        savePreferencesButtonText="Speichern"
        wholeDomain={true}
        acceptButtonText="Alles Akzeptieren"
        message='Um unsere Webseiten für Sie bestmöglich zu gestalten, zu verbessern und interessengerechte Inhalten bereitzustellen, verwenden wir Cookies. Durch Bestätigen des Buttons "Alle Akzeptieren" stimmen Sie der Verwendung zu. Über den Button "Details Anzeigen" können Sie auswählen, welche Cookies Sie zulassen wollen. Weitere Informationen erhalten Sie auf der Seiter unserer Datenschutzrichtlinien.'
        necessaryOptionText="Notwendige Cookies"
        showPreferencesOption={true}
        preferencesDefaultChecked={false}
        preferencesOptionText="YouTube/Vimeo"
        showStatisticsOption={false}
        showMarketingOption={false}
        styles={{
          dialog: {
            backgroundColor: "#fff",
            padding: "24px 16px",
            top: "0",
            position: "fixed",
            width: "100vw",
            zIndex: "99999",
          },
          message: {
            color: "rgb(41, 39, 39)",
            fontWeight: "300",
            padding: "24px 0",
            fontSize: "1rem",
          },
          policy: {
            color: "rgb(214, 46, 46)",
          },
          button: {
            border: "none",
            backgroundColor: "rgb(214, 46, 46)",
            color: "white",
            padding: "16px 8px",
            margin: "0 6px 0 0",
          },
          optionLabel: {
            height: "auto",
            width: "auto",
            minHeight: "14px",
            fontSize: "12pt",
            color: "rgb(41, 39, 39)",
            display: "inline-block",
            padding: "1px 0px 0px 20px",
            position: "relative",
            top: "0px",
            left: "0px",
            zIndex: 1,
            cursor: "default",
            verticalAlign: "top",
          },
        }}
      />
      <header>
        <div>
          <Logo finishedLoading={isLoading} />
          {activeNavItem ? (
            <Hamburger onClick={onBurgerClick} isActive={isToggleNavActive} />
          ) : null}
        </div>
        <Nav isActive={isToggleNavActive} activeItem={activeNavItem} />
      </header>
      {/* <main>{isLoading ? <Loader /> : children}</main> */}
      <main>{children}</main>
      <Footer isLoading={isLoading} />
    </div>
  );
};

export default Layout;
