import { useRef, useEffect } from "react";
const GuestbookForm = ({ showForm, closeForm, onFormSubmit, token }) => {
  const hiddenToken = useRef();

  useEffect(() => {
    if (hiddenToken.current) {
      hiddenToken.current.value = token;
      window.setTimeout(() => {
        console.log(hiddenToken.current);
      }, 2000);
    }
  }, [hiddenToken, token]);

  return (
    <div className={showForm ? "form-holder show-form" : "form-holder"}>
      <div className="guestbook-form-container">
        <form onSubmit={onFormSubmit}>
          <h2>Erstelle einen Gästebucheintrag</h2>
          <p>
            Es kann eine Weile dauern bis dein Eintrag veröffentlicht wird.
            Schau doch einfach später noch mal bei uns vorbei.
          </p>
          <div className="input-control">
            <label htmlFor="fullname">Name</label>
            <input
              required
              pattern="[a-zA-Z\s]*"
              type="text"
              name="title"
              id="fullname"
            />
          </div>
          <div className="input-control">
            <label htmlFor="email">Email</label>
            <input required type="email" name="email" id="email" />
          </div>
          <div className="input-control">
            <label htmlFor="message">Nachricht</label>
            <textarea
              required
              minLength="5"
              maxLength="480"
              rows="6"
              name="content"
              id="message"
            ></textarea>
          </div>
          <div
            className="input-control"
            style={{ marginBottom: "24px", fontSize: "12px" }}
          >
            Diese Website ist durch reCAPTCHA geschützt und es gelten die{" "}
            <a href="https://policies.google.com/privacy">
              Datenschutzbestimmungen
            </a>{" "}
            und{" "}
            <a href="https://policies.google.com/terms">Nutzungsbedingungen</a>{" "}
            von Google.
          </div>
          <div className="button-control">
            <div className="input-control">
              <input ref={hiddenToken} type="hidden" name="token" id="token" />
              <button
                onClick={closeForm}
                className="btn btn-default"
                type="button"
              >
                Abbrechen
              </button>
            </div>
            <div className="input-control">
              <button className="btn btn-default" type="submit">
                Senden
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GuestbookForm;
