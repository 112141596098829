const rootDir = "";
const mainMenu = {
  history: "/geschichte",
  memories: "/erinnerungen",
  school: "/schulleben",
  guestbook: "/gaestebuch",
};
const navItems = [
  {
    text: "Geschichte",
    target: rootDir + mainMenu.history,
    submenu: [
      // {
      //   text: "Das Gebäude",
      //   target: rootDir + mainMenu.history + "/das-gebaeude",
      // },
      // {
      //   text: "Die Menschen",
      //   target: rootDir + mainMenu.history + "/die-menschen",
      // },
      // {
      //   text: "Der Stadtteil",
      //   target: rootDir + mainMenu.history + "/der-stadtteil",
      // },
      // {
      //   text: "Die Gemeinde",
      //   target: rootDir + mainMenu.history + "/die-gemeinde",
      // },
      // {
      //   text: "Katholisch in Hamburg",
      //   target: rootDir + mainMenu.history + "/katholisch-in-hamburg",
      // },
    ],
  },
  {
    text: "Erinnerungen",
    target: rootDir + mainMenu.memories,
    submenu: [
      // {
      //   text: "Schüler*innen",
      //   target: rootDir + mainMenu.memories + "/schueler",
      // },
      // {
      //   text: "Lehrer*innen",
      //   target: rootDir + mainMenu.memories + "/lehrerschaft",
      // },
      // {
      //   text: "Schulleiter*innen",
      //   target: rootDir + mainMenu.memories + "/schulleitung",
      // },
      // {
      //   text: "Eltern",
      //   target: rootDir + mainMenu.memories + "/eltern",
      // },
    ],
  },
  {
    text: "Schulleben",
    target: rootDir + mainMenu.school,
    submenu: [
      {
        text: "Kulturforum 21",
        target: rootDir + mainMenu.school + "/kulturforum-21",
      },
      // {
      //   text: "News",
      //   target: rootDir + mainMenu.school + "/news",
      // },
      // {
      //   text: "Aktionen",
      //   target: rootDir + mainMenu.school + "/aktionen",
      // },
      // {
      //   text: "Wettbewerbe",
      //   target: rootDir + mainMenu.school + "/wettbewerbe",
      // },
      {
        text: "Archiv",
        target: rootDir + mainMenu.school + "/archiv",
      },
    ],
  },
  {
    text: "Gästebuch",
    target: rootDir + mainMenu.guestbook,
    submenu: [],
  },
];

const hashMapSubmenu = () =>
  navItems
    .filter((item) => item.submenu.length)
    .map((item) => item.submenu)
    .flatMap((item) => item)
    .map((item) => ({
      [item.target.slice(item.target.lastIndexOf("/") + 1)]: item.text,
    }))
    .reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      acc[key] = curr[key];
      return acc;
    }, {});

const getParentItem = (data) => {
  const output = {};
  data
    .filter((item) => item.submenu.length)
    .map((item) => {
      const sm = {};
      item.submenu.forEach((subitem) => {
        sm[subitem.target.slice(subitem.target.lastIndexOf("/") + 1)] =
          item.text;
      });
      return sm;
    })
    .flatMap((item) => item)
    .forEach((item) => {
      for (let key in item) {
        output[key] = item[key];
      }
    });
  return output;
};

export { navItems, hashMapSubmenu, getParentItem };
