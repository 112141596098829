import React from "react";

const GuestbookEntries = React.forwardRef(({ data, bgColor }, ref) => {
  return (
    <div ref={ref} className="guestbook-container">
      {data.length
        ? data.map((item, idx) => {
            return (
              <div
                data-id={idx}
                className="guestbook-entry shadow"
                key={item.id}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "64px",
                    height: "64px",
                    color: "#ffffff",
                    fontSize: "1.8rem",
                    backgroundColor: item.avatarBgColor,
                  }}
                >
                  <span>{item.initials}</span>
                </div>
                <p className="op">
                  <b>{item.fullname}</b> schrieb am
                </p>{" "}
                <i>
                  <time
                    title={new Date(item.date)}
                    dateTime={item.date}
                    className="live-timestamp"
                  >
                    {new Date(item.date).toLocaleDateString("de-DE")}
                  </time>
                </i>
                <p
                  className="message"
                  dangerouslySetInnerHTML={{
                    __html: item && item.message,
                  }}
                />
                <div className="timer-container">
                  <div className="timer"></div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
});

export default GuestbookEntries;
