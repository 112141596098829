import { useState, useEffect, useLayoutEffect } from "react";
import { fetcher } from "../utils/fetcher";
import { getCookie } from "../utils/getCookie";
import { imgLoader } from "../utils/imgLoader";
import { throttle } from "../utils/throttle";
import { getPostProp } from "../utils/getPostProp";
import { shuffleArray } from "../utils/shuffleArray";
import Layout from "./Layout";
import Postings from "./postings/Postings";
import Modal from "./Modal";

const Erinnerungen = () => {
  const [doneLoading, setDoneLoading] = useState(false);
  const [pageContent, setPageContent] = useState();
  const [categories, setCategories] = useState([]);
  const [postings, setPostings] = useState([]);

  const [showModal, setShowModal] = useState({
    active: false,
    src: {},
    isVid: false,
    itText: false,
    index: 0,
  });

  async function fetchCategories() {
    const categories = await fetch(
      process.env.REACT_APP_WP_API_URL + "categories"
    );
    const categoriesJson = await categories.json();
    setCategories(categoriesJson);
  }

  const onModalDismiss = () => {
    setShowModal({
      active: false,
      src: {},
      isVid: false,
      isText: false,
      index: 0,
    });
  };

  const findPostById = (id) =>
    postings.filter((post) => post.id === parseInt(id))[0];

  const onPostClick = ({ currentTarget }) => {
    const index = currentTarget.dataset.id;
    switch (currentTarget.dataset.category) {
      case "bild":
        const featuredImg = getPostProp(findPostById(index), [
          "embedded",
          "wp:featuredmedia",
          "0",
          "source_url",
        ]);

        setShowModal((prevState) => ({
          ...prevState,
          active: true,
          src: { src: featuredImg, size: "" },
          isVid: false,
          isText: false,
          index,
        }));

        break;
      case "text":
        const content = getPostProp(findPostById(index), ["content"]);
        const img = getPostProp(findPostById(index), ["contentImg"]);
        //console.log(img);
        setShowModal((prevState) => ({
          ...prevState,
          active: true,
          src: { content: content, img: img ? img : null },
          isVid: false,
          isText: true,
          index,
        }));

        break;
      case "video":
        const vid = getPostProp(findPostById(index), ["youtube"]);
        setShowModal((prevState) => ({
          ...prevState,
          active: true,
          src: { src: vid },
          isVid: true,
          isText: false,
          index,
        }));
        break;
      default:
    }
  };

  const prevNextImg = () => {};

  useLayoutEffect(() => {
    const onScroll = throttle((evt) => {
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector("header").classList.add("shrink-header");
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector("header").classList.remove("shrink-header");
        }
      }
    }, 15);
    window.addEventListener("scroll", onScroll);
    return () => {
      document.querySelector("header").classList.remove("shrink-header");
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    fetcher(process.env.REACT_APP_WP_API_URL + "pages?slug=erinnerungen").then(
      (data) => {
        setPageContent({
          header: data[0].title.rendered,
          content: data[0].content.rendered,
        });
      }
    );
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories.length) {
      fetcher(
        process.env.REACT_APP_WP_API_URL + "erinnerungen?_embed&per_page=100"
      ).then((data) => {
        //console.log(data);
        let images = [];
        const postingsTmp = data.map((post) => {
          const excerpt = post.excerpt.rendered;
          let img = null;
          let contentImg = null;
          if (post["_embedded"].hasOwnProperty("wp:featuredmedia")) {
            img = getPostProp(post, [
              "_embedded",
              "wp:featuredmedia",
              "0",
              "media_details",
              "sizes",
              "medium",
              "source_url",
            ]);
            contentImg = getPostProp(post, [
              "_embedded",
              "wp:featuredmedia",
              "0",
              "media_details",
              "sizes",
            ]);
            if (img) {
              images.push({ img: img });
            }
          }

          return {
            id: post.id,
            category: categories.filter(
              (cat) => cat.id === post.categories[0]
            )[0].slug,
            heading: post.title.rendered,
            date: new Date(post.modified).toLocaleDateString(),
            excerpt: excerpt.length ? excerpt : "",
            content: post.content.rendered,
            authorName: post.autor ? post.autor : "John Doe",
            authorAvatar: "img/eule.webp",
            img: img ? img : "",
            contentImg: contentImg ? contentImg : "",
            embedded: post._embedded,
            youtube: post.youtube,
          };
        });

        imgLoader(images).then((value) => {
          if (getCookie("rcl_preferences_consent")) {
            shuffleArray(postingsTmp);
            setPostings(postingsTmp);
            setDoneLoading(true);
          } else {
            const noVids = postingsTmp.filter(
              (post) => post.category !== "video"
            );
            shuffleArray(noVids);
            setPostings(noVids);
            setDoneLoading(true);
          }
        });
      });
    }
  }, [categories]);

  return (
    <Layout layoutClass="erinnerungen" activeNavItem="Erinnerungen">
      <Modal
        showModal={showModal}
        onModalDismiss={onModalDismiss}
        prevNextImg={prevNextImg}
        showPrevNext={false}
      />
      <Postings
        showDate={false}
        data={postings}
        onPostClick={onPostClick}
        pageContent={pageContent}
        doneLoading={doneLoading}
      />
    </Layout>
  );
};

export default Erinnerungen;
