const Post = (props) => {
  const {
    id,
    heading,
    date,
    showDate,
    category,
    excerpt,
    authorName,
    authorAvatar,
    img,
    onTransitionEnd,
    onPostClick,
  } = props;

  return (
    <div
      data-category={category}
      data-id={id}
      onClick={onPostClick}
      onTransitionEnd={onTransitionEnd}
      className={"card-container news-posting "}
    >
      {img ? <img src={img} alt={heading} /> : null}
      <div className="holder">
        <h3
          dangerouslySetInnerHTML={{
            __html: heading.length ? heading : null,
          }}
        />
        {showDate ? <p className="date">{date}</p> : null}
        <p
          dangerouslySetInnerHTML={{
            __html: excerpt.length ? excerpt : null,
          }}
        />
        <div className="author">
          <img src={authorAvatar} alt={authorName} />
          <span>von {authorName}</span>
        </div>
      </div>
    </div>
  );
};

export default Post;
