import { useLayoutEffect, useEffect, useState, useRef } from "react";
import { fetcher } from "../utils/fetcher";
import { throttle } from "../utils/throttle";
import { imgLoader } from "../utils/imgLoader";
import Layout from "./Layout";
import Loader from "./Loader";
import Modal from "./Modal";

const Subsite = ({ subSite, parentItem }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageContent, setPageContent] = useState();
  const [gallery, setGallery] = useState([]);
  const [showModal, setShowModal] = useState({
    active: false,
    src: {},
    isVid: false,
    isText: false,
    index: 0,
  });
  const galleryContainer = useRef();
  const animationEndCount = useRef(1);
  const setAnimDelay = (random) => {
    if (galleryContainer.current) {
      Array.from(galleryContainer.current.children).forEach((item) => {
        item.style.transitionDelay = random
          ? Math.random().toFixed(2) + "s"
          : "0s";
        random && item.classList.add("show-figure");
      });
      animationEndCount.current = 1;
    }
  };

  const onTransitionEnd = (event) => {
    animationEndCount.current = animationEndCount.current + 1;
    if (
      animationEndCount.current === galleryContainer.current.children.length
    ) {
      setAnimDelay(false);
    }
  };

  const onModalDismiss = () => {
    setShowModal({
      active: false,
      src: {},
      isVid: false,
      isText: false,
      index: 0,
    });
  };

  const prevNextImg = ({ currentTarget }) => {
    const {
      dataset: { direction },
    } = currentTarget;

    const activeGallery = gallery.filter((gal) => gal.active)[0];
    const idxNum = parseInt(showModal.index);
    const len = activeGallery.content.length;

    const nextPrevIdx =
      direction === "left"
        ? idxNum === 0
          ? len - 1
          : idxNum - 1
        : idxNum === len - 1
        ? 0
        : idxNum + 1;

    const newSrc = gallery.filter((gal) => gal.active)[0].content[nextPrevIdx];
    let currSrc = {};
    newSrc.srcset.forEach((item, idx, arr) => {
      if (parseInt(item.size.replace("w", "")) <= window.innerWidth) {
        currSrc = { src: item.src, size: item.size };
      }
    });

    setShowModal({
      active: true,
      src: {},
      isVid: false,
      isText: false,
      index: 0,
    });

    setTimeout(() => {
      setShowModal({
        active: true,
        src: currSrc,
        isVid: false,
        isText: false,
        index: nextPrevIdx,
      });
    }, 100);
  };

  const onImgClick = ({ target }) => {
    const {
      dataset: { srcset, index },
    } = target;

    const srcsetJSON = JSON.parse(srcset);
    let currSrc = {};
    srcsetJSON.forEach((item, idx, arr) => {
      if (parseInt(item.size.replace("w", "")) <= window.innerWidth) {
        currSrc = { src: item.src, size: item.size };
      }
    });
    setShowModal((prevState) => ({
      ...prevState,
      active: true,
      src: currSrc,
      isVid: false,
      isText: false,
      index,
    }));
  };

  useLayoutEffect(() => {
    const onScroll = throttle((evt) => {
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector("header").classList.add("shrink-header");
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector("header").classList.remove("shrink-header");
        }
      }
    }, 15);
    window.addEventListener("scroll", onScroll);
    return () => {
      document.querySelector("header").classList.remove("shrink-header");
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    fetcher(
      process.env.REACT_APP_WP_API_URL + `pages?slug=${subSite}&_embed`
    ).then((data) => {
      //console.log(data);
      fetcher(
        process.env.REACT_APP_WP_API_URL +
          `${
            subSite === "kulturforum-21" ? subSite.replace("-21", "") : subSite
          }?_embed`
      ).then((images) => {
        //console.log(images);
        const content = images.map((item, idx) => ({
          id: idx,
          img: item._embedded["wp:featuredmedia"]["0"]["media_details"][
            "sizes"
          ]["medium"]["source_url"],
          alt: item.title.rendered,
          srcset: Object.keys(
            item._embedded["wp:featuredmedia"]["0"]["media_details"]["sizes"]
          )
            .filter((key) =>
              item._embedded["wp:featuredmedia"]["0"]["media_details"]["sizes"][
                key
              ].hasOwnProperty("width")
            )
            .map((key) => ({
              src: item._embedded["wp:featuredmedia"]["0"]["media_details"][
                "sizes"
              ][key].source_url,
              size:
                item._embedded["wp:featuredmedia"]["0"]["media_details"][
                  "sizes"
                ][key].width + "w",
            })),
        }));

        setGallery([
          {
            id: subSite,
            title: "",
            content: content,
            active: true,
          },
        ]);
        setPageContent({
          header: data[0].title.rendered,
          content: data[0].content.rendered,
        });
      });
    });
  }, [subSite]);

  useLayoutEffect(() => {
    //console.log("called use effect IMG LOADER");
    if (gallery.length) {
      const activeGallery = gallery.filter((gallery) => gallery.active);
      imgLoader(activeGallery[0].content).then((values) => {
        setIsLoading(false);
        setTimeout(() => {
          setAnimDelay(true);
        }, 0);
      });
    }
  }, [gallery]);

  return (
    <Layout layoutClass="gaestebuch" activeNavItem={parentItem[subSite]}>
      <Modal
        showModal={showModal}
        onModalDismiss={onModalDismiss}
        prevNextImg={prevNextImg}
        showPrevNext={true}
      />
      <div className="container">
        <div className="main-heading">
          <h1>{pageContent ? pageContent.header : null}</h1>
        </div>
        <div
          className="mobile-text"
          dangerouslySetInnerHTML={{
            __html: pageContent && pageContent.content,
          }}
        />

        <div
          className={
            isLoading ? "loader-holder loader-visible" : "loader-holder"
          }
        >
          <Loader />
        </div>
        <div
          ref={galleryContainer}
          className={isLoading ? "gallery" : "gallery gallery-visible"}
        >
          {gallery.length
            ? gallery[0].content.map((img) => {
                return (
                  <div
                    key={img.img}
                    onTransitionEnd={onTransitionEnd}
                    className="gallery-item"
                  >
                    <img
                      src={img.img}
                      alt={img.alt}
                      data-srcset={JSON.stringify(img.srcset)}
                      data-index={img.id}
                      onClick={onImgClick}
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </Layout>
  );
};

export default Subsite;
