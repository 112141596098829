import { useNavigate } from "@reach/router";
import BookClosed from "../icons/BookClosed";
import BackpackRight from "../icons/BackpackRight";
import BookOpen from "../icons/BookOpen";
import Leaf from "../icons/Leaf";
const Card = ({
  bgClass,
  animClass,
  posClass,
  bgColorDelay,
  target,
  heading,
  icon,
}) => {
  const navigate = useNavigate();
  const rootDir = "";

  const navigateTo = (target) => (evt) => {
    evt.preventDefault();
    navigate(target);
  };

  const renderIcon = () => {
    switch (icon) {
      case "book-closed":
        return <BookClosed />;
      case "backpack-right":
        return <BackpackRight />;
      case "book-open":
        return <BookOpen />;
      case "leaf":
        return <Leaf />;
      default:
        break;
    }
  };

  return (
    <div
      onClick={navigateTo(rootDir + target)}
      className={`card shadow phat-border white-border ${animClass}`}
    >
      {/* <div className="bg-img-holder"></div> */}
      <div
        className={`bg-color ${bgClass} ${posClass}`}
        style={{ animationDelay: bgColorDelay }}
      >
        {renderIcon(icon)}
      </div>
      <h2>{heading}</h2>
    </div>
  );
};

export default Card;
