import { useState, useEffect } from "react";
import Layout from "./Layout";
import Loader from "./Loader";
import { throttle } from "../utils/throttle";

const Disclaimers = ({ disclaimer }) => {
  const [loadingDone, setFinishedLoading] = useState(false);
  const [pageContent, setPageContent] = useState();

  useEffect(() => {
    const getPageData = async () => {
      const req = await fetch(
        process.env.REACT_APP_WP_API_URL + "pages?slug=" + disclaimer
      );
      const reqJson = await req.json();
      setPageContent(reqJson[0].content.rendered);
      setFinishedLoading(true);
    };
    getPageData();

    const onScroll = throttle((evt) => {
      //console.dir(evt.target.scrollingElement.scrollTop);
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector("header").classList.add("shrink-header");
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector("header").classList.remove("shrink-header");
        }
      }
    }, 15);

    window.addEventListener("scroll", onScroll);
    return () => {
      document.querySelector("header").classList.remove("shrink-header");
      window.removeEventListener("scroll", onScroll);
    };
  }, [disclaimer]);

  return (
    <Layout layoutClass="geschichte">
      {loadingDone ? (
        <>
          <div
            className="text-content"
            dangerouslySetInnerHTML={{
              __html: pageContent && pageContent.length ? pageContent : null,
            }}
          ></div>
        </>
      ) : (
        <div
          style={{ minHeight: "50vh" }}
          className={
            loadingDone ? "loader-holder" : "loader-holder loader-visible"
          }
        >
          <Loader />
        </div>
      )}
    </Layout>
  );
};

export default Disclaimers;
