import {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { BiCommentAdd } from "react-icons/bi";
import { fetcher } from "../../utils/fetcher";
import { throttle } from "../../utils/throttle";
import Layout from "../Layout";
import GuestbookForm from "./GuestbookForm";
import GuestbookEntries from "./GuestbookEntries";

const Gaestebuch = ({ data }) => {
  const smlSliderContainerRef = useRef();
  const [sliderLength, setSliderLength] = useState(0);
  const [sliderElemHeight, setSliderElemHeight] = useState(0);
  const [pageContent, setPageContent] = useState();
  const [guestbookEntries, setGuestbookEntries] = useState([]);
  const [layoutTop, setLayoutTop] = useState(0);
  const [footerTop, setFooterTop] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [reToken, setReToken] = useState();
  //const grecaptcha = window.grecaptcha;
  const setInterval = window.setInterval;
  const clearInterval = window.clearInterval;

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setLayoutTop(node.getBoundingClientRect().top);
    }
  }, []);

  const getInitials = (name) => {
    const initials = name
      .split(" ")
      .map((item) => item.slice(0, 1).toUpperCase());
    return initials.length > 1 ? initials[0] + initials[1] : initials[0];
  };

  useEffect(() => {
    let to = setInterval(() => {
      //console.log(to);
      if (window.grecaptcha) {
        clearInterval(to);
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute("6LcQczQbAAAAAHy5EryjWZ2JQ44Wwn6EN9jofM_u", {
              action: "submit",
            })
            .then((token) => {
              setReToken(token);
            });
        });
      }
    }, 50);
  }, [clearInterval, setInterval]);

  useEffect(() => {
    if (reToken) {
      fetcher(process.env.REACT_APP_WP_API_URL + "pages?slug=gaestebuch").then(
        (pageData) => {
          //console.log(pageData);
          fetcher(
            process.env.REACT_APP_WP_API_URL + "guestbook?per_page=100"
          ).then((data) => {
            //console.log(data);
            const gbe = data.map((item) => ({
              id: item.id,
              fullname: item.title.rendered,
              message: item.content.rendered.replace(/<\/?[^>]+(>|$)/g, ""),
              date: item.date,
              avatarBgColor:
                "#" + Math.floor(Math.random() * 16777215).toString(16),
              initials: getInitials(item.title.rendered),
            }));
            setPageContent({
              header: pageData[0].title.rendered,
              content: pageData[0].content.rendered,
            });
            setGuestbookEntries(gbe);
          });
        }
      );
    }
  }, [reToken]);

  useLayoutEffect(() => {
    const onScroll = throttle((evt) => {
      //console.dir(evt.target.scrollingElement.scrollTop);
      if (window.innerWidth >= 768) {
        if (evt.target.scrollingElement.scrollTop > 20) {
          document.querySelector("header").classList.add("shrink-header");
        }
        if (evt.target.scrollingElement.scrollTop < 20) {
          document.querySelector("header").classList.remove("shrink-header");
        }
      }
    }, 15);
    window.addEventListener("scroll", onScroll);
    return () => {
      document.querySelector("header").classList.remove("shrink-header");
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useLayoutEffect(() => {
    const b = document.querySelector("footer").getBoundingClientRect().height;
    setFooterTop(window.innerHeight - (layoutTop + b));
  }, [layoutTop]);

  const onTransitionEnd = ({ target, propertyName }) => {
    if (propertyName === "left") {
      if (target.classList.contains("animate-in")) {
        const duration =
          target.querySelector(".message").textContent.split(/\s/).length * 550;
        target.querySelector(".timer").style.transitionDuration =
          duration + "ms";
        target.querySelector(".timer").classList.add("run");
        setTimeout(
          (target) => {
            target.classList.remove("animate-in");
            target.classList.add("animate-out");
            if (parseInt(target.dataset.id) !== sliderLength - 1) {
              setSliderElemHeight(
                target.nextElementSibling.getBoundingClientRect().height
              );
              target.nextElementSibling.classList.add("animate-in");
            } else {
              setSliderElemHeight(
                smlSliderContainerRef.current.children[0].getBoundingClientRect()
                  .height
              );
              smlSliderContainerRef.current.children[0].classList.add(
                "animate-in"
              );
            }
          },
          duration,
          target
        );
      } else {
        target.classList.remove("animate-out");
        target.querySelector(".timer").classList.remove("run");
      }
    }
  };

  const onAddCommentClick = (evt) => {
    setShowForm((prev) => !prev);
  };

  const closeForm = (evt) => {
    setShowForm(false);
  };

  const onFormSubmit = async (evt) => {
    evt.preventDefault();
    const elements = Array.from(evt.target.elements)
      .filter((el) => el.tagName !== "BUTTON")
      .map((el) => ({ name: el.name, value: el.value }))
      .reduce((acc, next) => {
        acc[next.name] = next.value;
        return acc;
      }, {});

    try {
      const postGuestbookEntry = await fetch("/guestbook.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: elements.title,
          content: elements.content,
          email: elements.email,
          token: elements.token,
        }),
      });
      const guestbookEntryRes = await postGuestbookEntry.json();
      if (guestbookEntryRes["success"]) {
        //console.log(guestbookEntryRes);
        evt.target.reset();
        setShowForm(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  function startAnimation() {
    const children = smlSliderContainerRef.current.children;
    setSliderElemHeight(children[0].getBoundingClientRect().height);
    children[0].classList.add("animate-in");
  }

  useLayoutEffect(() => {
    //console.log(Math.ceil(footerTop));
    setTimeout(() => {
      setSliderLength(smlSliderContainerRef.current.children.length);
      if (smlSliderContainerRef.current.children.length) startAnimation();
    }, 1000);
  }, [footerTop]);

  return (
    <>
      <GuestbookForm
        showForm={showForm}
        closeForm={closeForm}
        onFormSubmit={onFormSubmit}
        token={reToken}
      />
      <Layout layoutClass="gaestebuch" activeNavItem="Gästebuch">
        <div className="container">
          <div className="main-heading">
            <h1>{pageContent ? pageContent.header : null}</h1>
            <span onClick={onAddCommentClick}>
              <BiCommentAdd size={32} />
            </span>
          </div>
          <div
            className="mobile-text"
            dangerouslySetInnerHTML={{
              __html: pageContent && pageContent.content,
            }}
          />
          <div
            className="sidebar-layout"
            ref={measuredRef}
            style={{ height: sliderElemHeight + "px" }}
            onTransitionEnd={onTransitionEnd}
          >
            <GuestbookEntries
              ref={smlSliderContainerRef}
              data={guestbookEntries}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Gaestebuch;
