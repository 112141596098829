export function imgLoader(data) {
  //console.log(data);
  const images = data
    .filter((item) => item.img)
    .map((item) => item.img)
    .map((img) => {
      return new Promise((res, rej) => {
        const image = new Image();
        image.src = img;
        image.onload = () => {
          res(image);
        };
      });
    });
  return Promise.all(images);
}
